export const GET_ITEMS = 'GET_ITEMS';
export const ADD_CASE = 'ADD_CASE';
export const ADDING_CASE = 'ADDING_CASE';
export const DELETE_ITEM = 'DELETE_ITEM';
export const ITEMS_LOADING = 'ITEMS_LOADING';
export const USER_LOADING = 'USER_LOADING';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const REGISTER_EXISTING_CUSTOMER_SUCCESS =
  'REGISTER_EXISTING_CUSTOMER_SUCCESS';
export const REGISTER_EXISTING_CUSTOMER_FAIL =
  'REGISTER_EXISTING_CUSTOMER_FAIL';
export const REGISTER_LOADING = 'REGISTER_LOADING';
export const LOGIN_LOADING = 'LOGIN_LOADING';
export const GET_ERRORS = 'GET_ERRORS';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const CREATE_PICKUP = 'CREATE_PICKUP';
export const PICKUP_FAIL = 'PICKUP_FAIL';
export const CREATED_PICKUP = 'CREATED_PICKUP';
export const LOADING_USER_INFO = 'LOADING_USER_INFO';
export const LOAD_USER_INFO = 'LOAD_USER_INFO';
export const LOADED_USER_INFO = 'LOADED_USER_INFO';
export const LOADED_FAIL_USER_INFO = 'LOADED_FAIL_USER_INFO';
export const UPDATE_USER_FAILED = 'UPDATE_USER_FAILED';
export const ADDRESS_MODIFY_FAIL = 'ADDRESS_MODIFY_FAIL';
export const LOAD_ADDRESSES = 'LOAD_ADDRESSES';
export const MODIFIED_ADDRESS = 'MODIFIED_ADDRESS';
export const LOAD_ADDRESSES_FAIL = 'LOAD_ADDRESSES_FAIL';
export const LOADED_ADDRESSES = 'LOADED_ADDRESSES';
export const ADD_NEW_ADDRESS = 'ADD_NEW_ADDRESS';
export const GET_INVOICES = 'GET_INVOICES';
export const INVOICES_LOADING = 'INVOICES_LOADING';
export const INVOICES_LOADED = 'INVOICES_LOADED';
export const PRESCRIPTION_LOADING = 'PRESCRIPTION_LOADING';
export const PRESCRIPTION_LOADED = 'PRESCRIPTION_LOADED';
export const PRESCRIPTION_SUCCESS = 'PRESCRIPTION_SUCCESS';
export const PRESCRIPTION_SAVED = 'PRESCRIPTION_SAVED';
export const PRESCRIPTION_FAILED = 'PRESCRIPTION_FAILED';
export const PROFILE_UPDATED = 'PROFILE_UPDATED';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const ADDRESS_DELETE_FAIL = 'ADDRESS_DELETE_FAIL';
export const PROFILE_UPDATE_FAIL = 'PROFILE_UPDATE_FAIL';
export const LOADING_USERS = 'LOADING_USERS';
export const LOADED_USERS = 'LOADED_USERS';
export const LOADED_FAIL_USERS = 'LOADED_FAIL_USERS';
export const VERIFYING_USER = 'VERIFYING_USER';
export const VERIFIED_USER = 'VERIFIED_USER';
export const VERIFIED_FAIL_USER = 'VERIFIED_FAIL_USER';
export const RESET = 'RESET';
export const LOADED_UL20_USERS = 'LOADED_UL20_USERS';
export const LOADED_FAIL_UL20_USERS = 'LOADED_FAIL_UL20_USERS';
export const LINK_BILLING_ACCOUNT_ID_SUCCESS =
  'LINK_BILLING_ACCOUNT_ID_SUCCESS';
export const LINK_BILLING_ACCOUNT_ID_FAILED = 'LINK_BILLING_ACCOUNT_ID_FAILED';
export const LINK_BILLING_ACCOUNT_ID_LOADING =
  'LINK_BILLING_ACCOUNT_ID_LOADING';
