import {
  AUTH_ERROR,
  LOGIN_FAIL,
  LOGIN_LOADING,
  LOGIN_SUCCESS,
  LOGOUT_SUCCESS,
  REFRESH_TOKEN,
  REGISTER_EXISTING_CUSTOMER_FAIL,
  REGISTER_EXISTING_CUSTOMER_SUCCESS,
  REGISTER_FAIL,
  REGISTER_LOADING,
  REGISTER_SUCCESS,
  RESET,
  USER_LOADED,
  USER_LOADING,
} from '../actions/types';

const storeTokenAndReturnProfile = (
  payload = { accessToken: '', permissions: [], roles: [] }
) => {
  localStorage.setItem('token', payload.accessToken);
  localStorage.setItem('isAuthenticated', true);
  const rolesPermission = {
    permissions: payload?.permissions,
    roles: payload?.roles,
  };
  localStorage.setItem('profile', JSON.stringify(rolesPermission));
  const profileObj = {
    profile: rolesPermission,
    token: payload.accessToken,
    isAuthenticated: true,
  };

  return profileObj;
};

const profileItem = JSON.parse(localStorage.getItem('profile'));

const initialState = {
  token: localStorage.getItem('token'),
  isAuthenticated: localStorage.getItem('isAuthenticated'),
  profile: {
    permissions: profileItem?.permissions || [],
    roles: profileItem?.roles,
  },
  isLoading: false,
  user: null,
  registered: false,
  registeredFailed: false,
};

function reducer(state = initialState, action) {
  const { payload } = action;

  switch (action.type) {
    case RESET:
      return {
        ...state,
        registered: false,
        registeredFailed: false,
        isLoading: false,
      };
    case USER_LOADING:
      return {
        ...state,
        isLoading: true,
        registered: false,
        registeredFailed: false,
      };
    case REGISTER_LOADING:
    case LOGIN_LOADING:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: true,
        registeredFailed: false,
        registered: false,
      };
    case USER_LOADED:
      return {
        ...state,
        registered: false,
        registeredFailed: false,
        isAuthenticated: true,
        isLoading: false,
        user: action.payload,
      };
    case REFRESH_TOKEN:
      return state;
    case LOGIN_SUCCESS:
    case REGISTER_EXISTING_CUSTOMER_SUCCESS:
      const loginPayload = storeTokenAndReturnProfile(payload);
      return {
        ...state,
        registered: false,
        registeredFailed: false,
        isLoading: false,
        ...loginPayload,
      };
    case REGISTER_SUCCESS:
      const registerPayload = storeTokenAndReturnProfile(payload);
      return {
        ...state,
        ...registerPayload,
        isLoading: false,
        registered: true,
      };
    case REGISTER_EXISTING_CUSTOMER_FAIL:
    case LOGIN_FAIL:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: null,
        isLoading: false,
        registered: false,
        registeredFailed: false,
      };
    case LOGOUT_SUCCESS:
      localStorage.removeItem('token');
      localStorage.removeItem('isAuthenticated');
      localStorage.removeItem('user');
      localStorage.removeItem('profile');
      return state;

    case AUTH_ERROR:
    case REGISTER_FAIL:
      return {
        ...state,
        token: null,
        user: null,
        isAuthenticated: false,
        isLoading: false,
        registeredFailed: payload,
      };
    default:
      return state;
  }
}

export default reducer;
