import CircularProgress from '@material-ui/core/CircularProgress';

const Loader = ({ style, size, message = '' }) => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={style}
  >
    {message && <h2>{message}</h2>}
    <CircularProgress size={size} />
  </div>
);

export default Loader;
