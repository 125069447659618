import './css/global.css';

import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import React, { Suspense } from 'react';

import Loader from './components/Loader';
import { Provider } from 'react-redux';
import Routers from './components/Router';
import store from './store';
import SelectOfficeProvider from './utils/useSelectContext';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#5f8bfe',
    },
  },
});

function App() {
  return (
    <MuiThemeProvider theme={theme}>
      <Suspense
        fallback={
          <Loader size={60} style={{ width: '100vw', height: '100vh' }} />
        }
      >
        <Provider store={store}>
          <Routers />
        </Provider>
      </Suspense>
    </MuiThemeProvider>
  );
}

export default App;
