import {
  ADDRESS_DELETE_FAIL,
  ADDRESS_MODIFY_FAIL,
  ADD_NEW_ADDRESS,
  DELETE_ADDRESS,
  LOADED_ADDRESSES,
  LOAD_ADDRESSES,
  LOAD_ADDRESSES_FAIL,
  MODIFIED_ADDRESS,
  RESET,
} from '../actions/types';

const initialState = {
  loading: false,
  addresses: [],
  updated: false,
  created: false,
  error: false,
  deleted: false,

  updatedLoading: false,
  deletedLoading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        error: false,
      };
    case ADD_NEW_ADDRESS:
      return {
        ...state,
        created: true,
        loading: false,
      };
    case LOADED_ADDRESSES:
      return {
        ...state,
        addresses: action.payload,
        loading: false,
      };
    case LOAD_ADDRESSES:
      return {
        ...state,
        created: false,
        updated: false,
        deleted: false,
        error: false,
        errorAction: false,
        loading: true,
      };
    case MODIFIED_ADDRESS:
      return {
        ...state,
        updated: true,
        loading: false,
      };
    case DELETE_ADDRESS:
      return {
        ...state,
        deleted: true,
        loading: false,
      };
    case ADDRESS_MODIFY_FAIL:
    case ADDRESS_DELETE_FAIL:
      return {
        ...state,
        deleted: false,
        updated: false,
        created: false,
        loading: false,
        error: true,
      };
    case LOAD_ADDRESSES_FAIL:
      return {
        ...state,
        ...action.payload,
        error: true,
        loading: false,
      };
    default:
      return state;
  }
}
