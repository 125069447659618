import { isEmpty } from 'lodash';
import { createContext, useEffect, useState } from 'react';

export var SelectOfficeContext = createContext();
const SelectOfficeProvider = (props) => {
  const [currOffice, setCurrOffice] = useState();
  useEffect(() => {
    if (!currOffice) {
      // when it is null
      if (!isEmpty(window.sessionStorage.getItem('officeSelectedObject'))) {
        // not null
        setCurrOffice(
          JSON.parse(window.sessionStorage.getItem('officeSelectedObject'))
        );
      }
    } else {
      // not null
      window.sessionStorage.setItem(
        'officeSelectedObject',
        JSON.stringify(currOffice)
      );
    }
  }, [currOffice]);

  return (
    <SelectOfficeContext.Provider value={[currOffice, setCurrOffice]}>
      {props.children}
    </SelectOfficeContext.Provider>
  );
};

export default SelectOfficeProvider;
