import {
  LOADED_FAIL_USER_INFO,
  LOADED_USER_INFO,
  LOAD_USER_INFO,
  PROFILE_UPDATED,
  UPDATE_USER_FAILED,
  RESET,
} from '../actions/types';

const initialState = {
  loading: false,
  user: {
    firstName: '',
    lastName: '',
    id: null,
    license: '',
    billingAddress: '',
    defaultAddress: '',
    mainContactFirstName: '',
    mainContactLastName: '',
    mainContactEmail: '',
    officeName: '',
    officeHours: '',
    officeLunchHours: '',
    city: '',
    street: '',
    state: '',
    apartment: null,
    zipcode: '',
    office_work_days: '',
    practiceId: null,
    isStatus: '',
    email: '',
    phoneNumber: '',
    practiceName: '',
  },

  updated: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET:
      return {
        ...state,
        error: false,
        loading: false,
      };
    case UPDATE_USER_FAILED:
      return {
        ...state,
        error: true,
        updateFailed: true,
        message: action.payload,
        loading: false,
      };
    case PROFILE_UPDATED:
      return {
        ...state,
        loading: false,
        updated: true,
      };
    case LOADED_USER_INFO:
      return {
        ...state,
        user: action.payload,
        updated: false,
        loading: false,
      };
    case LOAD_USER_INFO:
      return {
        ...state,
        loading: true,
        updated: false,
      };
    case LOADED_FAIL_USER_INFO:
      return {
        ...state,
        message: action.payload,
        loading: false,
        error: true,
      };
    default:
      return state;
  }
};

export default userReducer;
