import { BrowserRouter as Router, Switch } from 'react-router-dom';

import React from 'react';
import history from './history';

import RouteChangeTracker from './RouteChangeTracker';
import SelectOfficeProvider from '../utils/useSelectContext';

const MyAccount = React.lazy(() => import('./myaccount/myAccount'));
const PrivateRoute = React.lazy(() => import('./Wrapper/PrivateRoute'));
const PublicRoute = React.lazy(() => import('./Wrapper/PublicRoute'));
const Booking = React.lazy(() => import('./booking/booking'));
const MyCases = React.lazy(() => import('./mycases/myCases'));
const Home = React.lazy(() => import('./Home'));
const Invoices = React.lazy(() => import('./invoices/invoices'));
const Login = React.lazy(() => import('./login/Login'));
const ManageAddress = React.lazy(() => import('./myaccount/manageAddress'));
const NewAddress = React.lazy(() => import('./myaccount/newAddr'));
const NewCase = React.lazy(() => import('./newcase/newCase'));
const NewInfo = React.lazy(() => import('./myaccount/newInfo'));
const RenderOpenMSG = React.lazy(() => import('./mycases/renderOpenMSG'));
const Signup = React.lazy(() => import('./login/Signup'));
const UpdateExistingAccount = React.lazy(() =>
  import('./login/UpdateExistingAccount')
);
const Customers = React.lazy(() => import('./admin/admin'));
const NotFound = React.lazy(() => import('./errors/page404'));
const ForgotPassword = React.lazy(() => import('./login/forgotPassword'));
const PrivacyStatement = React.lazy(() => import('./login/privacyStatement'));
const ResetPassword = React.lazy(() => import('./login/resetPassword'));
const TermsAndService = React.lazy(() => import('./login/termsAndService'));
const UpdatePassword = React.lazy(() => import('./login/updatePassword'));
const UpdatePasswordLanding = React.lazy(() =>
  import('./login/updatePasswordLanding')
);
const Routes = () => (
  <Router history={history}>
    <Switch>
      {/*Login and Sign up*/}
      <PublicRoute path="/login" component={Login} />
      <PublicRoute path="/update-account" component={UpdateExistingAccount} />
      <PublicRoute path="/signup" component={Signup} />
      <PublicRoute path="/privacyStatement" component={PrivacyStatement} />
      <PublicRoute path="/termsAndService" component={TermsAndService} />
      <PublicRoute path="/resetPassword" component={ResetPassword} />
      <PublicRoute path="/forgotPassword" component={ForgotPassword} />
      <PublicRoute path="/updatePassword" component={UpdatePassword} />
      <PublicRoute
        path="/updatePasswordLanding"
        component={UpdatePasswordLanding}
      />
      {/*Allow users to check, edit and add user data or office addresses*/}
      <PrivateRoute path="/" exact component={Home} />
      <PrivateRoute path="/customers" exact component={Customers} />
      <PrivateRoute path="/myAccount" exact component={MyAccount} />
      <PrivateRoute
        exact
        path="/myAccount/manageAddresses"
        component={ManageAddress}
      />

      <PrivateRoute path="/myAccount/newInfo" exact component={NewInfo} />
      <PrivateRoute path="/myAccount/updateInfo" exact component={NewInfo} />
      <PrivateRoute path="/myAccount/newAddress" exact component={NewAddress} />
      <PrivateRoute
        path="/myAccount/updateAddress/:id"
        exact
        component={NewAddress}
      />

      {/*Allow users to check submitted cases list*/}
      <PrivateRoute path="/myCases" exact component={MyCases} />
      <PrivateRoute path="/renderOpenMSG" exact component={RenderOpenMSG} />

      {/*Allow users to create New Case*/}
      <PrivateRoute path="/newCase" exact component={NewCase} />

      {/*Allow users to book a pickup*/}
      <PrivateRoute path="/booking" exact component={Booking} />

      {/*Allow users to check invoices list*/}
      <PrivateRoute path="/invoices" exact component={Invoices} />
      <PrivateRoute component={NotFound} />
      {/*<Route component={page404}/>*/}

      {/* <RouteChangeTracker /> */}
    </Switch>
  </Router>
);

export default Routes;
