import {
  PRESCRIPTION_FAILED,
  PRESCRIPTION_LOADED,
  PRESCRIPTION_LOADING,
  PRESCRIPTION_SAVED,
  PRESCRIPTION_SUCCESS,
} from '../actions/types';

const initialState = {
  loading: false,
  success: false,
  error: false,
  errorMessage: '',
  data: {
    contents: [],
    totalPages: 0,
  },
};

// eslint-disable-next-line import/no-anonymous-default-export
const prescriptionReducer = (state = initialState, action) => {
  switch (action.type) {
    case PRESCRIPTION_LOADING:
      return {
        ...state,
        loading: true,
        success: false,
        loaded: false,
        error: false,
        errorMessage: '',
        data: [],
      };
    case PRESCRIPTION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        saved: false,
        error: false,
        errorMessage: '',
        data: action.payload,
      };
    case PRESCRIPTION_SAVED:
      return {
        ...state,
        loading: false,
        success: false,
        saved: true,
        error: false,
        errorMessage: '',
        saved_data: action.payload,
      };
    case PRESCRIPTION_LOADED:
      return {
        ...state,
        loading: false,
        success: false,
        error: false,
        errorMessage: '',
        data: action.payload,
      };
    case PRESCRIPTION_FAILED:
      return {
        ...state,
        loading: false,
        loaded: false,
        success: false,
        error: true,
        errorMessage: action.payload,
      };
    default:
      return state;
  }
};

export default prescriptionReducer;
